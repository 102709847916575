pre {
    padding: 1em;
}

.logo {
    max-width: 300px;
}

.logo-small {
    max-width: 150px;
}

.nowrap {
    white-space: nowrap;
}

.badge {
    text-align: left;
    white-space: normal;
}

.spinner-border {
    /* Hack to render spinner properly within .table, which manipulates the border otherwise */
    border-bottom-width: 0.25em;
}

.table-fixed th {
    background: white;
    -webkit-position: sticky;
    position: sticky;
    top: 0;
}
